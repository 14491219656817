<template>
  <UiProductCard
    v-element-visibility="(isVisibility) => isVisibility && emit('visible')"
    :card="computedCard"
    :theme="theme"
    @click="$emit('click')"
    @click:tooltip="handleTooltipClick"
  />
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { vElementVisibility } from '@vueuse/components'

  import UiProductCard from '@academica-box/components/Cards/UiProductCard/UiProductCard.vue'

  import type { Card } from '@academica-box/components/Cards/UiProductCard/types'
  import { FilterKeys } from '@academica-box/types'

  import { useSubscribesStore } from '@academica-box/composables/store/subscribes'
  import { useUserStore } from '@academica-box/composables/store/user'

  import { useRouteQueryParams } from '@academica-box/composables/useRouteQueryParam'

  const route = useRoute()
  const router = useRouter()

  const viewport = useViewport()
  const isDesktop = computed(() => viewport.isGreaterOrEquals('lg'))

  const subscribesStore = useSubscribesStore()
  const { isUserSubscriptionActive } = storeToRefs(subscribesStore)
  const userStore = useUserStore()
  const { user } = storeToRefs(userStore)

  const emit = defineEmits(['click', 'visible'])

  const props = withDefaults(defineProps<{
    card: Card;
    theme?: 'primary' | 'secondary';
  }>(), {
    theme: 'primary',
  })

  const isProPage = route.path.includes('/pro')
  const isCatalogPage = route.path.includes('/catalog')

  const filterQueries = useRouteQueryParams({ [FilterKeys.is_subscribe_available]: 'string' })

  const isProButtonActive = computed(() => {
    return filterQueries.value[FilterKeys.is_subscribe_available] === '1' || isProPage || isUserSubscriptionActive.value
  })

  const computedCard = computed(() => {
    return {
      ...props.card,
      isSubscribeAvailable: props.card.isSubscribeAvailable && isProButtonActive.value,
    }
  })

  const handleTooltipClick = () => {
    if (isDesktop.value) {
      if (isCatalogPage && isUserSubscriptionActive.value) {
        return
      }

      if (!isUserSubscriptionActive.value && !isProPage) {
        router.push('/pro')
      }

      if ((!isCatalogPage && isUserSubscriptionActive.value) || isProPage) {
        router.push('/catalog/?is_subscribe_available=1')
      }
    }
  }
</script>
